body {
  font-family: "Noto Sans", sans-serif;
  /* font-family: "Courier New", monospace; */
  touch-action: "none";
  user-select: "none";
}
.no-drag {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
